
/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 70px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 70px; /* Vertically center the text there */
}

/* Custom styles
--------------------------------------------------- */

.clickable:hover {
  cursor: pointer;
}

a.no-style {
  text-decoration: none;
  color: white;
}

.asset-img {
  width:20px;
  height:20px;
}

.bg-translucent-light {
  background-color: rgba(255, 255, 255, 0.03);
}

.bg-translucent-dark {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-toggle::after {
  content: none;
}

 /* Pull to Refresh */
.ptr--icon {
  color:white !important;
}
.ptr--text {
  color:white !important;
}
